








import {Component, Vue} from 'vue-property-decorator'

const ANIMATIONS = ['full-slide-top', 'full-slide-right', 'full-slide-down', 'full-slide-left']

@Component({
	components: {
	}
})
export default class App extends Vue {
	routingAnimation() {
		const index = Math.floor(Math.random() * ANIMATIONS.length)
		return ANIMATIONS[index]
	}
}
