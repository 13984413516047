

















































































































































import {Component, Vue} from 'vue-property-decorator'
import LogoTextGradient from 'icons/logo/LogoTextGradient.vue'
import IconCheck from 'icons/icons/IconCheck.vue'
import {FileDefs} from 'filedefs/FileDefs'
import LandingHeader from 'landing/LandingHeader.vue'

@Component({
	components: {
		LandingHeader,
		LogoTextGradient,
		IconCheck
	}
})

export default class Landing extends Vue {
	private externalFiles = FileDefs
}
