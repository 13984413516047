
























































































import {Component, Vue} from 'vue-property-decorator'
import LandingHeader from 'landing/LandingHeader.vue'

@Component({
	components: {
		LandingHeader
	}
})

export default class PermissionsPage extends Vue {
	//
}
