












import {Component, Vue} from 'vue-property-decorator'
import LogoTextGradient from 'icons/logo/LogoTextGradient.vue'

@Component({
	components: {
		LogoTextGradient
	}
})

export default class LandingHeader extends Vue {
	//
}
